import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../../atoms/Button/Button';
import Text from '../../atoms/Text/Text';
import { css } from '@emotion/react';

export default function BeneficiosForm({ bgColorInput, bgColorSubmit }) {
  const [envio, setEnvio] = useState('');

  const boldText = css`
    font-weight: bold;
    text-decoration: underline;
  `;

  const title1Css = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(62 / var(--width_base))));
    font-family: 'Montserrat Bold', sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(17 / var(--width_base))));
      line-height: calc(100vw * (calc(17 / var(--width_base))));
      color: #93ba1f;
      margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
    }
  `;
  const space_form = css`
    @media (max-width: 767px) {
      padding-right: calc(100vw * (calc(25 / var(--width_base))));
      padding-left: calc(100vw * (calc(25 / var(--width_base))));
    }
  `;

  const title2Css = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-top: calc(100vw * (calc(30 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(27 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(17 / var(--width_base))));
      line-height: calc(100vw * (calc(17 / var(--width_base))));
      color: #93ba1f;
    }
  `;

  const formAceptCss = css`
    &.form-acept {
      margin-top: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const submitCss = css`
    margin-top: calc(100vw * (calc(30 / var(--width_base))));
    padding-left: calc(100vw * (calc(50 / var(--width_base))));
    padding-right: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(10 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
      min-height: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const EresPropietarioSi = css`
    border: 2px solid #7fbc03 !important;
    width: calc(100vw * (calc(14 / var(--width_base)))) !important;
    height: calc(100vw * (calc(14 / var(--width_base)))) !important;
    padding: calc(100vw * (calc(2 / var(--width_base)))) !important;
    color: red !important;
    appearance: none !important;
    border-radius: 100% !important;
    &:checked {
      background-color: #7fbc03 !important;
    }
    &:not(:checked) {
      background-color: white !important;
    }
  `;
  const EresPropietarioNo = css`
    border: 2px solid #7fbc03 !important;
    width: calc(100vw * (calc(14 / var(--width_base)))) !important;
    height: calc(100vw * (calc(14 / var(--width_base)))) !important;
    padding: calc(100vw * (calc(2 / var(--width_base)))) !important;
    color: red !important;
    appearance: none !important;
    border-radius: 100% !important;
    &:checked {
      background-color: #7fbc03 !important;
    }
    &:not(:checked) {
      background-color: white !important;
    }
  `;
  const AcceptBox = css`
    border: 2px solid #7fbc03 !important;
    width: calc(100vw * (calc(12 / var(--width_base)))) !important;
    height: calc(100vw * (calc(12 / var(--width_base)))) !important;
    padding: calc(100vw * (calc(2 / var(--width_base)))) !important;
    color: white !important;
    appearance: none !important;
    &:checked {
      background-color: #7fbc03 !important;
    }
    &:not(:checked) {
      background-color: white !important;
    }
  `;

  const text3Css = css`
    @media (max-width: 767px) {
      margin-right: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  return (
    <section>
      <Formik
        initialValues={{
          EresPropietario: '',
          NombresPropietario: '',
          ApellidosPropietario: '',
          DNIOCEPropietario: '',
          TelefonoPropietario: '',
          MailPropietario: '',
          NombresReferido: '',
          ApellidosReferido: '',
          DNIOCEReferido: '',
          TelefonoReferido: '',
          Acepto_condiciones: '',
          MailReferido: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.NombresPropietario)
            errors.NombresPropietario = 'Campo requerido';
          if (!values.ApellidosPropietario)
            errors.ApellidosPropietario = 'Campo requerido';
          if (!values.DNIOCEPropietario)
            errors.DNIOCEPropietario = 'Campo requerido';
          if (!values.TelefonoPropietario)
            errors.TelefonoPropietario = 'Campo requerido';
          if (!values.MailPropietario)
            errors.MailPropietario = 'Campo requerido';
          else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              values.MailPropietario
            )
          )
            errors.MailPropietario = 'Correo Invalido';
          if (!values.NombresReferido)
            errors.NombresReferido = 'Campo requerido';
          if (!values.ApellidosReferido)
            errors.ApellidosReferido = 'Campo requerido';
          if (!values.DNIOCEReferido) errors.DNIOCEReferido = 'Campo requerido';
          if (!values.TelefonoReferido)
            errors.TelefonoReferido = 'Campo requerido';
          if (!values.MailReferido) errors.MailReferido = 'Campo requerido';
          if (!values.EresPropietario)
            errors.EresPropietario = 'Campo requerido';
          else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              values.MailReferido
            )
          )
            errors.MailReferido = 'Correo Invalido';
          if (!values.Acepto_condiciones)
            errors.Acepto_condiciones = 'Campo requerido';
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          try {
            const formId = 997;
            const formData = new FormData();
            formData.append('_wpcf7_unit_tag', `wpcf7-f${formId}-o1`);
            formData.append('NombresPropietario', values.NombresPropietario);
            formData.append(
              'ApellidosPropietario',
              values.ApellidosPropietario
            );
            formData.append('DNIOCEPropietario', values.DNIOCEPropietario);
            formData.append('TelefonoPropietario', values.TelefonoPropietario);
            formData.append('MailPropietario', values.MailPropietario);
            formData.append('EresPropietario', values.EresPropietario);
            formData.append('NombresReferido', values.NombresReferido);
            formData.append('ApellidosReferido', values.ApellidosReferido);
            formData.append('DNIOCEReferido', values.DNIOCEReferido);
            formData.append('TelefonoReferido', values.TelefonoReferido);
            formData.append('MailReferido', values.MailReferido);

            fetch(
              `${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/997/feedback`,
              {
                method: 'POST',
                body: formData,
              }
            )
              .then((res) => res.json())
              .then((json) => {
                if (json.status === 'mail_sent') {
                  setEnvio(true);
                  setSubmitting(false);
                  resetForm();
                } else {
                  setEnvio(false);
                }
              });
          } catch (error) {
            console.error(error);
            setEnvio(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Text
              data={`DATOS DEL <span style="color: #93ba1f;">PROPIETARIO</span>`}
              colorText="white"
              align="center"
              styleCss={title1Css}
              data-aos="fade-right"
              data-aos-delay="200"
            />
            <div className="form-group form-radios">
              <Text data="¿ERES PROPIETARIO?" styleCss={text3Css} />
              <div>
                <label htmlFor="EresPropietarioSi" className="form-label">
                  SI
                  <Field
                    className="form-control"
                    css={EresPropietarioSi}
                    type="radio"
                    name="EresPropietario"
                    id="EresPropietarioSi"
                    value="SI"
                  />
                </label>
                <label htmlFor="EresPropietarioNo" className="form-label">
                  NO
                  <Field
                    className="form-control"
                    css={EresPropietarioNo}
                    type="radio"
                    name="EresPropietario"
                    id="EresPropietarioNo"
                    value="NO"
                  />
                </label>
                <ErrorMessage
                  className="form-error"
                  name="EresPropietario"
                  component="div"
                />
              </div>
            </div>

            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                css
                type="text"
                name="NombresPropietario"
                id="NombresPropietario"
                placeholder="NOMBRES*"
              />
              <ErrorMessage
                className="form-error"
                name="NombresPropietario"
                component="div"
              />
            </div>
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="text"
                name="ApellidosPropietario"
                id="ApellidosPropietario"
                placeholder="APELLIDOS*"
              />
              <ErrorMessage
                className="form-error"
                name="ApellidosPropietario"
                component="div"
              />
            </div>
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="number"
                name="DNIOCEPropietario"
                id="DNIOCEPropietario"
                placeholder="DNI O CE*"
              />
              <ErrorMessage
                className="form-error"
                name="DNIOCEPropietario"
                component="div"
              />
            </div>
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="tel"
                name="TelefonoPropietario"
                id="TelefonoPropietario"
                placeholder="TELÉFONO*"
              />
              <ErrorMessage
                className="form-error"
                name="TelefonoPropietario"
                component="div"
              />
            </div>
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="email"
                name="MailPropietario"
                id="MailPropietario"
                placeholder="MAIL*"
              />
              <ErrorMessage
                className="form-error"
                name="MailPropietario"
                component="div"
              />
            </div>
            <Text
              data={`DATOS <span style="color: #93ba1f;">REFERIDO</span>`}
              colorText="white"
              align="center"
              styleCss={title2Css}
              data-aos="fade-right"
              data-aos-delay="200"
            />
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="text"
                name="NombresReferido"
                id="NombresReferido"
                placeholder="NOMBRES*"
              />
              <ErrorMessage
                className="form-error"
                name="NombresReferido"
                component="div"
              />
            </div>
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="text"
                name="ApellidosReferido"
                id="ApellidosReferido"
                placeholder="APELLIDOS*"
              />
              <ErrorMessage
                className="form-error"
                name="ApellidosReferido"
                component="div"
              />
            </div>
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="number"
                name="DNIOCEReferido"
                id="DNIOCEReferido"
                placeholder="DNI O CE*"
              />
              <ErrorMessage
                className="form-error"
                name="DNIOCEReferido"
                component="div"
              />
            </div>
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="tel"
                name="TelefonoReferido"
                id="TelefonoReferido"
                placeholder="TELÉFONO*"
              />
              <ErrorMessage
                className="form-error"
                name="TelefonoReferido"
                component="div"
              />
            </div>
            <div className="form-group" css={space_form}>
              <Field
                className="form-control"
                type="email"
                name="MailReferido"
                id="MailReferido"
                placeholder="MAIL*"
              />
              <ErrorMessage
                className="form-error"
                name="MailReferido"
                component="div"
              />
            </div>
            <div className="form-group text-center">
              <label
                className="form-acept"
                css={formAceptCss}
                htmlFor="Acepto_condiciones"
              >
                <Field
                  className="accept-box"
                  css={AcceptBox}
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                &nbsp;&nbsp;&nbsp;Acepto{' '}
                <a
                  css={boldText}
                  href="/terminos-y-condiciones/"
                  target="_blank"
                  rel="noreferrer"
                >
                  política de privacidad
                </a>
                .
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
            </div>
            <div className="text-center">
              <Button
                styleEle={submitCss}
                typeEle="submit"
                styleTypeEle="submit"
                disabled={isSubmitting}
              >
                INGRESAR DATOS
              </Button>
            </div>
            {envio === false && envio !== '' && (
              <div className="">Error en el envio</div>
            )}
            {envio === true && envio !== '' && (
              <div className="">Correo enviado</div>
            )}
          </Form>
        )}
      </Formik>
    </section>
  );
}
